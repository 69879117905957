/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  reduxForm, getFormValues, change, SubmissionError, getFormSyncErrors, updateSyncErrors, touch
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { Beforeunload } from 'react-beforeunload';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import SignupBanner from '../ReactComponent/SignupBanner';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import DuplicateShipmentWhite from '../../assets/icons/DuplicateShipmentWhite.svg';
import CancelWhite from '../../assets/icons/CancelWhite.svg';
import {
  measurementValidate, isUSZip, isCANPostal, listCompleted
} from '../../utils/validator';
import {
  putReduxServiceLevelQuotes,
  putReduxServiceLevelCode
} from '../CreateQuoteForm/actions';
import {
  submitShipment,
  getServiceQuotes,
  getAccessorials,
  BOLcall, formDataToApi
} from './api';
import {
  Icon,
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  FormContainer,
  ModalTitle,
  ModalBody,
  ButtonDiv,
  LastActions,
} from './css';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import { getQuoteByDivisionAndQuoteNumber } from '../../services/quotes';
import formApiToData from '../../utils/apiMapping';
import {
  postShipment,
  getShipmentByDivisionAndProBill,
  postShipmentDraft,
  getShipmentByDivisionAndShipmentId
} from '../../services/shipments';
import {
  scrollToTop,
  getZip,
  isCanadianAddress,
  homeUrlNoTrailingSlash,
  validatePostal,
  getCity,
  formatDate,
  getTimeObject,
  checkBillToAccountProtectedStatus,
  scrollToRef,
  getCountry,
} from '../../utils/func';
import {
  DeliveryTypes,
  Divisions,
  PaymentMethods,
  SamedayServiceType,
  PrefillNumberTypes,
  QuoteStatuses,
  BillToMethods,
  shipmentPickupServiceTypes,
  IsYesOrNo,
  CommerceServiceType
} from '../../utils/enums';
import { endpoints, routes } from '../../utils/constants';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import { list as unitsList } from '../ReactComponent/Measurement/Units/utils';
import { getPreferences } from '../../services/users';
import AddressPickerModal from '../ReactComponent/AddressPickerModal';

const formName = 'CreateShipmentForm';
let apiReadyTime = '';

const initialValues = {
  division: Divisions.Freight.name,
  deliveryType: { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' },
  measurementType: unitsList[0],
  accessorials: {},
  dangerDisable: false,
  measurementList: [{}],
  paymentMethod: { name: PaymentMethods.Prepaid, desc: 'PrepaidDesc' },
  numberTypeSelected: {
    name: 'None',
    value: 'none'
  },
  regularScheduledPickup: { name: IsYesOrNo.No, value: false },
  accessByTrailer: { name: IsYesOrNo.Yes, value: true }
};

class CreateShipmentForm extends React.Component {
  constructor(props) {
    super(props);
    const { fields } = props;
    this.steps = [
      fields.step1Title.value,
      fields.step2Title.value,
      fields.step3Title.value,
      fields.step4Title.value,
      fields.step5Title.value,
      fields.step6Title.value];
    this.state = {
      currentStep: 0,
      data: {},
      accList: [],
      modalIsOpen: false,
      errorMessage: null,
      quoteNumberError: null,
      preExistingValuesExist: false,
      userHasBeenWarned: false,
      isManageContactPermission: false,
      cancelShipment: false,
      embargoErrorMessage: false
    };
    this.addressFieldsRef = React.createRef();
  }

  updatePickupDate = (readyDate, closingDate) => {
    const { dispatch } = this.props;
    const currentDate = new Date();
    const readyDateFormatted = getTimeObject(readyDate);
    const closingDateFormatted = getTimeObject(closingDate);

    dispatch(change(formName, 'pickupDate', formatDate(currentDate)));
    dispatch(change(formName, 'readyTime.hours', readyDateFormatted.hours));
    dispatch(change(formName, 'readyTime.minutes', readyDateFormatted.minutes));
    dispatch(change(formName, 'readyTime.amOrPm', readyDateFormatted.amOrPm));
    dispatch(change(formName, 'closingTime.hours', closingDateFormatted.hours));
    dispatch(change(formName, 'closingTime.minutes', closingDateFormatted.minutes));
    dispatch(change(formName, 'closingTime.amOrPm', closingDateFormatted.amOrPm));
  };

  formPrefData = (resData) => {
    const { dispatch } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const dontUsePref = (urlParams.get('quoteNumber') || urlParams.get('shipmentId') || urlParams.get('probillNumber')) ? true : false;
    return {
      ...resData,
      fromContactId: dontUsePref ? undefined : resData.fromContactId,
      toContactId: dontUsePref ? undefined : resData.toContactId,
      deliveryType: dontUsePref ? undefined : this.getDeliveryType(resData.serviceType),
      regularScheduledPickup: dontUsePref ? undefined : this.getRegularScheduledPickup(resData.isPickupScheduled),
      billTo: dontUsePref ? undefined : dispatch(change(formName, 'billTo', resData.billToAccountNumber)),

    };
  };

  componentDidMount() {
    const {
      division, isAuthenticated, dispatch, formData, userId, userEmail
    } = this.props;
    if (isAuthenticated) {
      dispatch(change(formName, 'division', division));
      dispatch(change(formName, 'quoteDest', userEmail));
    }
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const currentDate = new Date();
      const readyDate = new Date();
      const closingDate = new Date();
      readyDate.setHours(currentDate.getHours(), currentDate.getMinutes() + 15);
      closingDate.setHours(17, 0, 0, 0); // 5:00 PM...
      this.updatePickupDate(readyDate, closingDate);
      getPreferences(userId, division)
        .then((res) => {
          if (res.status === 200 && res.data) {
            apiReadyTime = res.data.readyTime;
            this.updatePickupDate(new Date(res.data.readyTime),
              res.data.closingTime !== '0001-01-01T00:00:00' ? new Date(res.data.closingTime) : closingDate);
            const formedData = this.formPrefData(res.data);
            this.setState({
              ...formedData
            });
          }
        });
      const divisionQueryParam = urlParams.get('division');
      if (divisionQueryParam) {
        dispatch(change(formName, 'division', divisionQueryParam));
      }
      const serviceTypeQueryParam = urlParams.get('serviceType');
      dispatch(change(
        formName, 'serviceType', serviceTypeQueryParam || shipmentPickupServiceTypes[division || divisionQueryParam || Divisions.Freight.name][0]
      ));
      getAccessorials({
        isAuthenticated, division: divisionQueryParam || division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
      }).then(() => {
        if (urlParams.get('probillNumber')) {
          dispatch(change(formName, 'numberTypeSelected', {
            name: 'Use Shipment Number', value: PrefillNumberTypes.shipment
          })
          );

          dispatch(change(formName, 'quoteOrShipmentNumber', urlParams.get('probillNumber')));

          this.handleShipmentNumbers(
            urlParams.get('division') || division || Divisions.Freight.name,
            urlParams.get('probillNumber') || formData.quoteOrShipmentNumber
          );
        } else if (urlParams.get('quoteNumber')) {
          dispatch(change(formName, 'numberTypeSelected', {
            name: 'Use Quote Number', value: PrefillNumberTypes.quote
          })
          );

          dispatch(change(formName, 'quoteOrShipmentNumber', urlParams.get('quoteNumber')));

          this.handleQuoteNumbers(
            urlParams.get('division') || division || Divisions.Freight.name,
            urlParams.get('quoteNumber') || formData.quoteOrShipmentNumber
          );
        } else if (urlParams.get('shipmentId')) {
          this.handleShipmentId(
            urlParams.get('division') || division || Divisions.Freight.name,
            urlParams.get('shipmentId')
          );
        }
      });
    }
  }

  nextStep = async (e) => {
    e?.preventDefault();
    const {
      formData: { measurementList, shipperAddress, consigneeAddress },
      division,
      formData,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelQuotes,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelCode,
      dispatch,
      isAuthenticated,
      formSyncErrors
    } = this.props;
    const shipperCountry = getCountry(formData.shipperAddress);
    const consigneeCountry = getCountry(formData.consigneeAddress);
    const hasMeasureMents =
      this.state.currentStep !== 1 ? true : measurementList ? true : false;
    if (this.props.valid && hasMeasureMents) {
      if (this.state.currentStep === 0) {
        if ((t('EmbargoFlag') === 'ON')
          && ((formData.shipperAddress && formData.shipperAddress.includes('BC'))
            || (formData.consigneeAddress && formData.consigneeAddress.includes('BC'))
            || (formData.shipperAddress && formData.shipperAddress.includes('AB'))
            || (formData.consigneeAddress && formData.consigneeAddress.includes('AB'))
            || (formData.shipperAddress && formData.shipperAddress.includes('SK'))
            || (formData.consigneeAddress && formData.consigneeAddress.includes('SK'))
            || (formData.shipperAddress && formData.shipperAddress.includes('MB'))
            || (formData.consigneeAddress && formData.consigneeAddress.includes('MB')))) {
          if ((shipperCountry !== 'US') && (consigneeCountry !== 'US')) {
            const errorMessage = t('EmbargoErrorMessage');
            this.setState({ modalIsOpen: true, errorMessage, embargoErrorMessage: true });
          }
        } else if ((t('EmbargoUSFlag') === 'ON') && (formData && formData.division === Divisions.Freight.name)) {
          if (shipperCountry === 'US' || consigneeCountry === 'US') {
            const errorMessage = t('EmbargoUSWarning');
            this.setState({ modalIsOpen: true, errorMessage, embargoErrorMessage: true });
          }
        }
        validatePostal(getZip(formData.shipperAddress), formData.division || division || Divisions.Freight.name).then((msg) => {
          const error = formSyncErrors;
          if (!Array.isArray(msg)) {
            error.shipperAddress = msg;
          } else {
            error.shipperAddress = undefined;
            if (!msg.find((c) =>
              c.cityName && c.cityName.toLowerCase() === getCity(formData.shipperAddress).toLowerCase()
            )) {
              this.setState({ addressPickerModalOpen: true, AddressOptions: msg.filter((a) => a.cityName), addressPickField: 'shipperAddress' });
              return;
            }
          }
          if (error.shipperAddress) {
            scrollToRef(this.addressFieldsRef);
            dispatch(touch(formName, 'shipperAddress'));
            throw dispatch(updateSyncErrors(formName, error));
          }
          // eslint-disable-next-line no-shadow
          validatePostal(getZip(formData.consigneeAddress), formData.division || division || Divisions.Freight.name).then((msg) => {
            // eslint-disable-next-line no-shadow
            const error = formSyncErrors;
            if (!Array.isArray(msg)) {
              error.consigneeAddress = msg;
            } else {
              error.consigneeAddress = undefined;
              if (!msg.find((c) =>
                c.cityName && c.cityName.toLowerCase() === getCity(formData.consigneeAddress).toLowerCase()
              )) {
                this.setState({ addressPickerModalOpen: true, AddressOptions: msg.filter((a) => a.cityName), addressPickField: 'consigneeAddress' });
                return;
              }
            }
            if (error.consigneeAddress) {
              scrollToRef(this.addressFieldsRef);
              dispatch(touch(formName, 'consigneeAddress'));
              throw dispatch(updateSyncErrors(formName, error));
            }
            this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
              setTimeout(() => scrollToTop(), 200);
            });
          });
        });
        if (isAuthenticated) {
          checkBillToAccountProtectedStatus(this.props.dispatch, this.props.division, this.props.userId, this.props.billingAccounts);
        }
        const USShipment = (
          isCANPostal(getZip(shipperAddress)) && isUSZip(getZip(consigneeAddress)))
          || (isUSZip(getZip(shipperAddress)) && isCANPostal(getZip(consigneeAddress)));
        dispatch(change(formName, 'USShipment', USShipment));

        // if (USShipment) {
        //   getAccessorials({
        //     isAuthenticated, USQuote: true, division: division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
        //   });
        // } else {
        // getAccessorials({
        //   isAuthenticated, division: division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
        // });
        // }
      }
      if (this.state.currentStep === 1) {
        if (!listCompleted(measurementList)) {
          return;
        }
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
          scrollToTop();
          setTimeout(() => scrollToTop(), 200);
        });
      }
      if (this.state.currentStep === 2) {
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
          scrollToTop();
          setTimeout(() => scrollToTop(), 200);
        });
      } else if (this.state.currentStep === 3) {
        getServiceQuotes({
          putReduxServiceLevelQuotes,
          putReduxServiceLevelCode,
          formData,
          prevStep: this.prevStep,
          openModal: this.openModal
        });
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
          scrollToTop();
          setTimeout(() => scrollToTop(), 200);
        });
      }
    }
  };

  updateState = (name, value) => this.setState({ [name]: value });

  prevStep = (e) => {
    e?.preventDefault();
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
    scrollToTop();
  };

  editStepOne = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 0 });
  };

  editStepTwo = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 1 });
  };

  editStepThree = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 2 });
  };

  editStepFour = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 3 });
  };

  handleSubmit = async () => {
    const {
      serviceLevelCode,
      formData,
      isAuthenticated,
      userId,
      userEmail
    } = this.props;
    const { currentStep } = this.state;
    if (isAuthenticated) {
      const changedData = formDataToApi(formData, userId, serviceLevelCode, userEmail);
      await postShipment(changedData).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.setState(
            {
              data: res.data
            },
            () => scrollToTop()
          );
        }
      }).catch(() => {
        throw new SubmissionError({});
      });
    } else {
      await submitShipment({
        serviceLevelCode,
        formData,
        updateState: this.updateState,
        prevStep: this.prevStep
      }).catch(() => {
        throw new SubmissionError({});
      });
    }
    if (currentStep === 4) {
      scrollToTop();
      this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
        setTimeout(() => scrollToTop(), 200);
      });
    }
  };

  openBOL = () => {
    const { formData: { division }, userEmail, userId } = this.props;
    const { data: { probillNumber } } = this.state;
    if (probillNumber) {
      BOLcall({
        probillNumber, division, emailAddress: userEmail, userId
      });
    }
  };

  openInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  handlePreviousQuotesLink = (event) => {
    event.preventDefault();
    const quoteOrShipmentType =
      this.props.formData &&
      this.props.formData.numberTypeSelected &&
      this.props.formData.numberTypeSelected.value;

    if (quoteOrShipmentType === PrefillNumberTypes.quote) {
      this.openInNewTab('/manage-quotes');
    }

    if (quoteOrShipmentType === PrefillNumberTypes.shipment) {
      this.openInNewTab('/manage-shipments');
    }
  };

  getPaymentMethod = (paymentMethod) => {
    const { dispatch } = this.props;
    let newPaymentMethod;

    if (paymentMethod === PaymentMethods.Collect) {
      newPaymentMethod = {
        name: PaymentMethods.Collect,
        desc: 'CollectDesc'
      };
    }

    if (paymentMethod === PaymentMethods.Prepaid) {
      newPaymentMethod = {
        name: PaymentMethods.Prepaid,
        desc: 'PrepaidDesc'
      };
    }

    if (paymentMethod === PaymentMethods.ThirdParty) {
      newPaymentMethod = {
        name: PaymentMethods.ThirdParty,
        desc: 'ThirdPartyDesc'
      };
    }

    dispatch(change(formName, 'paymentMethod', newPaymentMethod));
  };

  setThirdPartyMethod = (billTo, thidpartyDetails) => {
    const { dispatch } = this.props;
    let thirdyPartyBillto;

    const hasThirdPartyDetailsKeys = Object.keys(thidpartyDetails).some((k) => thidpartyDetails[k]);

    if (hasThirdPartyDetailsKeys) {
      thirdyPartyBillto = BillToMethods.enterBillTo;
      dispatch(change(formName, 'thirdPartyAccountNumber', billTo));
      dispatch(change(formName, 'billTo', ''));
    } else {
      thirdyPartyBillto = BillToMethods.pickBillTo;
      dispatch(change(formName, 'billTo', billTo));
    }

    dispatch(change(formName, 'billToMethod', thirdyPartyBillto));
  };

  getDeliveryType = (serviceType) => {
    const { dispatch } = this.props;
    let newDeliveryType;

    if (serviceType === SamedayServiceType.Commercial) {
      newDeliveryType = {
        name: DeliveryTypes.Commercial,
        desc:
          'CommercialServiceDesc'
      };
    }

    if (serviceType === SamedayServiceType.Residential || serviceType === CommerceServiceType.Residential) {
      newDeliveryType = {
        name: DeliveryTypes.Residential,
        desc:
          'ResidentialServiceDesc'
      };
    }

    dispatch(change(formName, 'deliveryType', newDeliveryType));
  };

  getRegularScheduledPickup = (regularScheduledPickup) => {
    const { dispatch } = this.props;
    let userPrefPickup;

    if (regularScheduledPickup === IsYesOrNo.Yes) {
      userPrefPickup = {
        name: IsYesOrNo.Yes,
        value: true
      };
    }

    if (regularScheduledPickup === IsYesOrNo.No) {
      userPrefPickup = {
        name: IsYesOrNo.No,
        value: false
      };
    }

    dispatch(change(formName, 'regularScheduledPickup', userPrefPickup));
  };

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    const {
      serviceType,
      billTo,
      paymentMethod,
      billingAddress,
      billingAddressAdditional,
      billingCompanyName,
      billingContactName,
      billingEmail,
      billingPhoneNumber,
      billingPhoneNumberExt
    } = data;
    const objBilling = {
      billingAddress,
      billingAddressAdditional,
      billingCompanyName,
      billingContactName,
      billingEmail,
      billingPhoneNumber,
      billingPhoneNumberExt
    };

    dispatch(change(formName, 'shipperNickname', ''));
    dispatch(change(formName, 'consigneeNickname', ''));
    Object.keys(data).map((field) => !(field === 'paymentMethod' || field === 'serviceType'
      // ignore outdated billTo account (ECE-1856)
      || (field === 'billTo' && !this.props.billingAccounts.some((account) => account.accountNumber === data.billTo)))
      && dispatch(change(formName, field, data[field])));
    if (serviceType) this.getDeliveryType(serviceType);
    if (paymentMethod) this.getPaymentMethod(paymentMethod);
    if (paymentMethod === PaymentMethods.ThirdParty && objBilling) {
      this.setThirdPartyMethod(billTo, objBilling);
    }
  };

  openModal = (quoteNumber) => {
    if (quoteNumber && quoteNumber.serviceLevelError === 'serviceLevelError') {
      quoteNumber.serviceLevelErrorArray.errors.map((error) => {
        if (error.errorCode === 'SD00024'
          || error.errorMessage === 'GF99999'
          || error.errorMessage === 'SD00010'
          || error.errorCode === 'GF99999'
          || error.errorCode === 'SD00010'
        ) {
          this.setState({ serviceLevelErrorObj: error });
        }
        return undefined;
      });
    }
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    const { dispatch } = this.props;
    this.setState({ modalIsOpen: false, embargoErrorMessage: false, errorMessage: '' });
    dispatch(change(formName, 'rerouteToPickupModal', false));
  };

  cancelShipment() {
    const errorMessage = t('CannotCancelMsg');
    this.setState({ modalIsOpen: true, errorMessage, cancelShipment: true });
  }

  handleCreateByNumberError = (type) => {
    const { dispatch } = this.props;
    switch (type) {
      case QuoteStatuses.UnderReview:
        this.setState({ errorMessage: t('QuoteUnderReview') });
        break;
      case QuoteStatuses.USQuote:
        this.setState({ errorMessage: t('UsQuoteNumberError') });
        break;
      case endpoints.shipping.quotes:
        this.setState({ errorMessage: t('Invalid quote number, please enter another number') });
        break;
      case endpoints.shipping.shipments:
        this.setState({ errorMessage: t('Invalid shipment number, please enter another number') });
        break;
      default:
        break;
    }
    this.setState({ modalIsOpen: true });
    dispatch(change(formName, 'quoteOrShipmentNumber', ''));
  };

  handleShipmentId = (division, shipmentId) => {
    getShipmentByDivisionAndShipmentId(shipmentId)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          const { accList } = this.state;
          data.accList = accList;
          const formedData = formApiToData(data);
          this.setState(
            {
              data: formedData,
              errorMessage: null,
              preExistingValuesExist: true
            },
            () => {
              this.dispatchFormValues(formedData);
            }
          );
        }
      });
  };

  handleQuoteNumbers = (division, quoteNumber) => {
    getQuoteByDivisionAndQuoteNumber(division, quoteNumber)
      .then((response) => {
        if (response.data) {
          const { data } = response;

          const { accList } = this.state;
          data.accList = accList;
          if (data.shipmentStatus === QuoteStatuses.UnderReview) {
            this.handleCreateByNumberError(QuoteStatuses.UnderReview);
          } else {
            const formedData = formApiToData(data);
            this.setState(
              {
                data: formedData,
                errorMessage: null,
                quoteNumberError: null,
                preExistingValuesExist: true
              },
              () => {
                this.dispatchFormValues(formedData);
              }
            );
          }
        }
      })
      .catch(() => {
        getQuoteByDivisionAndQuoteNumber(division, quoteNumber, true).then(
          (response) => {
            if (response.data) {
              const { data } = response;

              // Deny the use of US Quote numbers...
              if (data.quoteType === QuoteStatuses.USQuote) {
                this.handleCreateByNumberError(QuoteStatuses.USQuote);
              }
            }
          }
        )
          .catch(() => {
            this.handleCreateByNumberError(endpoints.shipping.quotes);
          });
      });
  };

  handleShipmentNumbers = (division, shipmentNumber) => {
    getShipmentByDivisionAndProBill(division, shipmentNumber)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          const { accList } = this.state;
          data.accList = accList;
          const formedData = formApiToData(data);
          this.setState(
            {
              data: formedData,
              errorMessage: null,
              preExistingValuesExist: true
            },
            () => {
              this.dispatchFormValues(formedData);
            }
          );
        }
      })
      .catch(() => {
        this.handleCreateByNumberError(endpoints.shipping.shipments);
      });
  };

  handleExistingQuoteOrShipment = () => {
    const { formData } = this.props;
    const quoteOrShipment =
      formData &&
      formData.numberTypeSelected &&
      formData.numberTypeSelected.value;
    const quoteOrShipmentNumber = formData && formData.quoteOrShipmentNumber;

    if (quoteOrShipment !== PrefillNumberTypes.none) {
      const { division } = formData;

      if (quoteOrShipment === PrefillNumberTypes.quote) {
        this.handleQuoteNumbers(division, quoteOrShipmentNumber);
      }

      if (quoteOrShipment === PrefillNumberTypes.shipment) {
        this.handleShipmentNumbers(division, quoteOrShipmentNumber);
      }
    }
  };

  handleFormChange = (e) => {
    const target = e.target.name;
    const changeWatchedFieldsList = ['shipperAddress', 'consigneeAddress', 'editMeasurement', 'deleteMeasurement', 'accessorials'];
    const { formData } = this.props;
    const quoteOrShipment =
      formData &&
      formData.numberTypeSelected &&
      formData.numberTypeSelected.value;
    // store prevShipperAddress and consignee Address
    const prevShipperAddress = this.state.data.shipperAddress.split(',').slice(1).toString();
    const prevConsigneeAddress = this.state.data.consigneeAddress.split(',').slice(1).toString();
    if (changeWatchedFieldsList.includes(String(target).split('.')[0]) && quoteOrShipment === PrefillNumberTypes.quote) {
      e.preventDefault();
      const { preExistingValuesExist, userHasBeenWarned } = this.state;
      const hasBeenWarned = userHasBeenWarned === true;
      // Check if the current shipper City/postal address is matching with addresses in the quote data
      if (e.target.name === 'shipperAddress') {
        const currentShippingAdd = e.target.value.split(',').slice(1).toString();
        if (String(prevShipperAddress) !== String(currentShippingAdd)) {
          if (preExistingValuesExist) {
            if (!hasBeenWarned) {
              this.setState({
                modalIsOpen: true
              });
            }
          }
        }
      }
      // Check if the current consignee City/postal address is matching with addresses in the quote data
      if (e.target.name === 'consigneeAddress') {
        const currentConsigneeAdd = e.target.value.split(',').slice(1).toString();
        if (String(prevConsigneeAddress) !== String(currentConsigneeAdd)) {
          if (preExistingValuesExist) {
            if (!hasBeenWarned) {
              this.setState({
                modalIsOpen: true
              });
            }
          }
        }
      }
    }
  };

  handleFormClick = (e) => {
    const target = e.target.alt || e.target.innerHTML;
    const changeWatchedFieldsList = ['add more part file', 'Add another item'];
    const { formData } = this.props;
    const quoteOrShipment =
      formData &&
      formData.numberTypeSelected &&
      formData.numberTypeSelected.value;
    if (changeWatchedFieldsList.includes(String(target)) && quoteOrShipment === PrefillNumberTypes.quote) {
      e.preventDefault();
      const { preExistingValuesExist, userHasBeenWarned } = this.state;
      const hasBeenWarned = userHasBeenWarned === true;

      if (preExistingValuesExist) {
        if (!hasBeenWarned) {
          this.setState({
            modalIsOpen: true
          });
        }
      }
    }
  };

  handleModalButtons = (event) => {
    const { division, formData, dispatch } = this.props;
    event.preventDefault();

    const {
      target: { name }
    } = event;

    if (name === 'createNewQuote') {
      this.setState({
        userHasBeenWarned: true
      });
      window.open(`${routes.createQuote}?recreate=true&division=${division}&quoteNumber=${formData.quoteNumber}`);
    } else if (name === 'routeToPickup') {
      window.location.href = routes.createPickup;
    } else if (name === 'cancelButton') {
      // this.handleExistingQuoteOrShipment();
      this.closeModal();
    } else if (name === 'acceptErrorMessage') {
      dispatch(change(formName, 'numberTypeSelected', {
        name: 'None', value: PrefillNumberTypes.none
      }));
    } else if (name === 'returnToStepTwo') {
      setTimeout(() => scrollToTop(), 200);
    }
    this.closeModal();
  };

  goToManage = () => {
    const manageUrl = `${homeUrlNoTrailingSlash()}${routes.manageShipments}`;
    window.location.href = manageUrl;
  };

  saveDraft = () => {
    const {
      formData, userId, userEmail, serviceLevelCode
    } = this.props;
    const changedData = formDataToApi(formData, userId, serviceLevelCode, userEmail);

    postShipmentDraft(changedData).then((res) => {
      if (res.status === 200 || res.status === 201) {
        this.goToManage();
      }
    });
  };

  handleAddressPickSubmit = (selectedAddress) => {
    const { dispatch, formData } = this.props;
    const { addressPickField } = this.state;
    const newVal = formData[addressPickField].replace(getCity(formData[addressPickField]), selectedAddress.cityName);
    dispatch(change(formName, addressPickField, newVal));
    this.closeAddressModal(selectedAddress);
  };

  closeAddressModal = (selectedAddress) => {
    this.setState({ addressPickerModalOpen: false, AddressOptions: [] },
      () => {
        if (selectedAddress && selectedAddress.cityName) {
          this.nextStep();
          return;
        }
        const { dispatch, formSyncErrors } = this.props;
        const { addressPickField } = this.state;
        const error = formSyncErrors;
        error[addressPickField] = t('AddressNotFound');
        // eslint-disable-next-line consistent-return
        return dispatch(updateSyncErrors(formName, error));
      });
  };

  openContactPermissionModal = () => {
    const { fields } = this.props;
    const errorMessage = t(fields.ManageContactPermissionWarning.value);
    this.setState({
      modalIsOpen: true, errorMessage, currentStep: 0, isManageContactPermission: true
    });
  };

  render() {
    const {
      fields,
      formData,
      valid,
      formSyncErrors,
      handleSubmit,
      submitSucceeded,
      dispatch,
      serviceLevelQuotes,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelCode,
      serviceLevelCode,
      isAuthenticated,
      accountNumbers,
      billingAccounts
    } = this.props;
    const {
      currentStep,
      accList,
      modalIsOpen,
      errorMessage,
      quoteNumberError,
      preExistingValuesExist,
      userHasBeenWarned,
      data: { probillNumber },
      fromContactId,
      toContactId,
      isManageContactPermission,
      cancelShipment,
      embargoErrorMessage
    } = this.state;
    const quoteOrShipmentType =
      formData &&
      formData.numberTypeSelected &&
      formData.numberTypeSelected.value;
    const validNumberTypeSelected = quoteOrShipmentType
      ? quoteOrShipmentType
      : false;
    // eslint-disable-next-line max-len
    const rerouteToPickupText = "We noticed you're shipping from the US. Currently, we only offer the ability to request a pickup when shipping from the US. We have automatically switched your shipment to a pickup with Bill of Lading.";
    const LTLCustomerNumber = 'LTL: 1.866.Day.Ross (329-7677)';
    const CommerceCustomerNumber = 'Commerce: 1.877.726.3329';
    return (
      <>
        <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
        <FormProgressBar current={currentStep} steps={this.steps} />
        {this.state.addressPickerModalOpen && <AddressPickerModal
          onClose={this.closeAddressModal}
          handleSubmit={this.handleAddressPickSubmit}
          Options={this.state.AddressOptions}
        />}
        <Modal
          isOpen={modalIsOpen || (formData && formData.rerouteToPickupModal)}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <ModalBody className="justify-content-center">
            <ModalTitle>
              {embargoErrorMessage
                ? t('Alert')
                : isManageContactPermission
                  ? t(fields.ManageContactPermissionTitle.value)
                  : errorMessage && !cancelShipment
                    ? t('Error')
                    : formData && formData.rerouteToPickupModal
                      ? t('SwitchingToPickup')
                      : t(null)}
            </ModalTitle>
            {errorMessage ?
              <>
                {(isManageContactPermission || cancelShipment || embargoErrorMessage) ? t('') : t('LossAllYourChanges')}
                <p><strong>{errorMessage}</strong></p>
                <p>{isManageContactPermission || embargoErrorMessage ? t('') : t('PleaseContactCustomerService')}</p>
                <p>{errorMessage && cancelShipment ? LTLCustomerNumber : t('')}</p>
                <p>{errorMessage && cancelShipment ? CommerceCustomerNumber : t('')}</p>
              </> :
              <>
                {formData && formData.rerouteToPickupModal ?
                  <p>{rerouteToPickupText}</p>
                  : this.state.serviceLevelErrorObj && this.state.serviceLevelErrorObj.errorCode === 'SD00024'
                    ? <p>{t(fields.SeasonalServiceError.value)}</p>
                    :
                    (this.state.serviceLevelErrorObj &&
                      (this.state.serviceLevelErrorObj.errorMessage === 'GF99999' ||
                        this.state.serviceLevelErrorObj.errorMessage === 'SD00010' ||
                        this.state.serviceLevelErrorObj.errorCode === 'GF99999' ||
                        this.state.serviceLevelErrorObj.errorCode === 'SD00010'))
                      ?
                      <>
                        <p>{t('PleaseContactSupportHotline')}</p>
                      </>
                      :
                      <>
                        <p>{t('editLockedfieldWarningMsg')}</p>
                      </>}
              </>}
            <ButtonDiv className="row">
              <div className="col-sm-12">
                {
                  embargoErrorMessage ?
                    <>
                      <SecondaryButton
                        onClick={(e) => this.handleModalButtons(e)}
                        className="active"
                        name="returnToStepTwo"
                        style={{ marginBottom: '20px' }}
                      >
                        {t('Okay')}
                        <span className="icon" />
                      </SecondaryButton>
                    </> :
                    isManageContactPermission || (errorMessage && cancelShipment) ?
                      <SecondaryButton
                        onClick={(e) => this.handleModalButtons(e)}
                        className="active"
                        name="okay"
                      >
                        {t('Okay')}
                        <span className="icon" />
                      </SecondaryButton> :
                      (errorMessage && !cancelShipment)
                        ||
                        (this.state.serviceLevelErrorObj &&
                          (this.state.serviceLevelErrorObj.errorMessage === 'GF99999' ||
                            this.state.serviceLevelErrorObj.errorMessage === 'SD00010' ||
                            this.state.serviceLevelErrorObj.errorCode === 'GF99999' ||
                            this.state.serviceLevelErrorObj.errorCode === 'SD00010'))
                        ?
                        <SecondaryButton
                          onClick={(e) => this.handleModalButtons(e)}
                          className="active"
                          name="acceptErrorMessage"
                        >
                          {t('Accept')}
                          <span className="icon" />
                        </SecondaryButton> :
                        formData && formData.rerouteToPickupModal ?
                          <>
                            <SecondaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              name="cancel"
                            >
                              {t('Cancel')}
                              <span className="icon" />
                            </SecondaryButton>
                            <PrimaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              name="routeToPickup"
                            >
                              {t('RouteToPickup')}
                              <span className="icon" />
                            </PrimaryButton>
                          </>
                          : <>
                            <PrimaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              style={{ marginBottom: '20px' }}
                              name="cancelButton"
                            >
                              {t('ReturnToShipment')}
                              <span className="icon" />
                            </PrimaryButton>
                            <SecondaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              name="createNewQuote"
                            >
                              {t('CreateaNewQuote')}
                              <span className="icon" />
                            </SecondaryButton>
                          </>
                }
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
        <SignupBanner loggedIn={isAuthenticated} />
        <FormContainer>
          {submitSucceeded ? (
            <>
              <LastActions className="float-right " $msButtonWidth="33.25Rem">
                <Column>
                  <Row>
                    <Column>
                      <PrimaryButton style={{ margin: '0px', width: '33.25Rem' }} className="active" onClick={() => this.openBOL()}>
                        {t('DownloadShippingDocuments')}
                      </PrimaryButton>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <MDBDropdown className="float-right" >
                        <MDBDropdownToggle >
                          <Row>
                            <Column $tablet={10} $mobile={10}>
                              {t('MoreOptions')}
                            </Column>
                            <span className="icon icon-more-options" />
                          </Row>
                        </MDBDropdownToggle>
                        <StyledMDBDropdownMenu className="text-white bg-dark" basic>
                          <DropdownItem
                            onClick={() => {
                              window.location.href =
                                `${routes.createShipment}?recreate=true&division=${formData && formData.division}&probillNumber=${probillNumber}`;
                              return window.location.href;
                            }}>
                            <DropdownItemIcon iconSrc={DuplicateShipmentWhite} />
                            {t('DuplicateShipment')}
                          </DropdownItem>
                          <DropdownItem onClick={() => this.cancelShipment()}>
                            <DropdownItemIcon iconSrc={CancelWhite} />
                            {t('CancelShipment')}
                          </DropdownItem>
                        </StyledMDBDropdownMenu>
                      </MDBDropdown>
                    </Column>
                  </Row>
                </Column>
              </LastActions>
              <Step6
                formData={formData}
                quoteData={this.state.data}
                serviceLevelQuotes={this.props.serviceLevelQuotes}
                chosenServiceLevelCode={this.props.serviceLevelCode}
                parentFields={fields}
                billingAccounts={billingAccounts}
              />
            </>
          ) : (
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              onChange={(e) => preExistingValuesExist && !userHasBeenWarned && this.handleFormChange(e)}
              onClick={(e) => preExistingValuesExist && !userHasBeenWarned && this.handleFormClick(e)}
            >
              {this.state.currentStep === 4 && valid && (
                <>
                  <ButtonGroup>
                    <SecondaryButton
                      type="button"
                      onClick={this.prevStep}
                      className={`${valid ? 'active' : ''} ${this.state.currentStep === 0 ? 'hidden' : ''}`}>
                      <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                      {t('Back')}
                    </SecondaryButton>
                    <SecondaryButton type="button" className="float-right" onClick={this.saveDraft}>
                      {t('SaveDraft')}
                    </SecondaryButton>
                    <PrimaryButton type="submit" className="submit float-right">
                      <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                      {t('SubmitShipment')}
                    </PrimaryButton>
                  </ButtonGroup>
                </>
              )}
              <Step1
                step={currentStep}
                formData={formData}
                getServiceLevel={this.getServiceLevel}
                dispatch={dispatch}
                loggedIn={isAuthenticated}
                accountNumbers={accountNumbers}
                handlePreviousQuotesLink={this.handlePreviousQuotesLink}
                handleExistingQuoteOrShipment={
                  this.handleExistingQuoteOrShipment
                }
                quoteNumberError={quoteNumberError}
                quoteOrShipmentType={quoteOrShipmentType}
                validNumberTypeSelected={validNumberTypeSelected}
                preExistingValuesExist={preExistingValuesExist}
                parentFields={fields}
                billingAccounts={billingAccounts}
                fromContactId={fromContactId}
                toContactId={toContactId}
                isLoggedIn={isAuthenticated}
                addressFieldsRef={this.addressFieldsRef}
                openContactPermissionModal={this.openContactPermissionModal}
              />
              <Step2
                formData={formData}
                step={currentStep}
                valid={valid}
                formName={formName}
                dispatch={dispatch}
                accessorialsList={accList}
                preExistingValuesExist={preExistingValuesExist}
                parentFields={fields}
                error={formSyncErrors}
                loggedIn={isAuthenticated}
              />
              <Step3
                accessorialsList={accList}
                formData={formData}
                step={currentStep}
                dispatch={dispatch}
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                preExistingValuesExist={preExistingValuesExist}
                parentFields={fields}
                loggedIn={isAuthenticated}
              />
              <Step4
                step={currentStep}
                formData={formData}
                parentFields={fields}
                apiReadyTime={apiReadyTime}
              />
              <Step5
                formData={formData}
                step={currentStep}
                editStepOne={this.editStepOne}
                editStepTwo={this.editStepTwo}
                editStepThree={this.editStepThree}
                editStepFour={this.editStepFour}
                serviceLevelQuotes={serviceLevelQuotes}
                putReduxServiceLevelCode={putReduxServiceLevelCode}
                serviceLevelCode={serviceLevelCode}
                parentFields={fields}
                billingAccounts={billingAccounts}
              />
              {this.state.currentStep === 4 && valid ? (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} ${this.state.currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <SecondaryButton type="button" className="float-right" onClick={this.saveDraft}>
                    {t('SaveDraft')}
                  </SecondaryButton>
                  <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('SubmitShipment')}
                  </PrimaryButton>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} 
                      ${this.state.currentStep === 0 ? 'hidden' : ''
                    }`}
                  >
                    <Icon
                      className="icon-left"
                      src={ArrowLeftOrange}
                      alt="back"
                    />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton
                    type="button"
                    onClick={this.nextStep}
                    className={`${valid && !(currentStep === 1 && !listCompleted(formData.measurementList)) ? 'active' : ''}`}
                  >
                    <Icon className="icon" src={ArrowRightWhite} alt="next" />
                    {t('Next')}
                  </PrimaryButton>
                </ButtonGroup>
              )}
            </form>
          )}
          <QuestionsBlock />
        </FormContainer>
      </>
    );
  }
}

export const addressValidate = (values, dispatch, props, fieldName) => {
  const { shipperAddress, consigneeAddress, division } = values;
  const error = { ...props.asyncErrors };
  if (fieldName && fieldName.includes('Nickname')) {
    const addressFieldName = `${fieldName.split('Nickname')[0]}Address`;
    props.touch(addressFieldName); // turn on error messages
    return props.asyncValidate(values, dispatch, props, addressFieldName); // evaluate whether the address field will fail or not
  }
  if (shipperAddress && consigneeAddress && shipperAddress === consigneeAddress) {
    return new Promise(() => {
      const throwError = { ...props.asyncErrors, consigneeAddress: t('NoSameAddress'), shipperAddress: t('NoSameAddress') };
      throw throwError;
    });
  }
  if (division === Divisions.Sameday.name) {
    if (shipperAddress && !isCanadianAddress(shipperAddress)) {
      return new Promise(() => {
        const throwError = { ...props.asyncErrors, shipperAddress: t('NoServiceOutsideCanada') };
        throw throwError;
      });
    }
    if (consigneeAddress && !isCanadianAddress(consigneeAddress)) {
      return new Promise(() => {
        const throwError = { ...props.asyncErrors, consigneeAddress: t('NoServiceOutsideCanada') };
        throw throwError;
      });
    }
  } else if (division === Divisions.Freight.name) {
    if (fieldName === 'shipperAddress' && shipperAddress && !isCanadianAddress(shipperAddress)) {
      dispatch(change(formName, 'rerouteToPickupModal', true));
      return new Promise(() => {
        const throwError = { ...props.asyncErrors, [fieldName]: t('UsePickupWithBolForUSShipments') };
        throw throwError;
      });
    }
  }
  if (shipperAddress && !isCanadianAddress(shipperAddress) && consigneeAddress && !isCanadianAddress(consigneeAddress)) {
    return new Promise(() => {
      const throwError = { ...props.asyncErrors, shipperAddress: t('OneAddressMustBeCanadian'), consigneeAddress: t('OneAddressMustBeCanadian') };
      throw throwError;
    });
  }

  if (props.asyncErrors
    && (props.asyncErrors.shipperAddress === t('NoSameAddress') || props.asyncErrors.shipperAddress === t('OneAddressMustBeCanadian'))) {
    error.shipperAddress = undefined;
  }
  if (props.asyncErrors
    && (props.asyncErrors.consigneeAddress === t('NoSameAddress') || props.asyncErrors.consigneeAddress === t('OneAddressMustBeCanadian'))) {
    error.consigneeAddress = undefined;
  }
  error[fieldName] = undefined;
  const hasError = Object.keys(error).reduce((accum, curr) => (error[curr] ? true : accum), false);
  return hasError ? new Promise(() => { throw error; }) : Promise.resolve();
};

CreateShipmentForm = reduxForm({
  form: formName,
  initialValues,
  validate: measurementValidate,
  asyncValidate: addressValidate,
  asyncBlurFields: ['consigneeAddress', 'shipperAddress', 'consigneeNickname', 'shipperNickname']
})(CreateShipmentForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  serviceLevelQuotes: state.user.serviceLevelQuotes,
  serviceLevelCode: state.user.serviceLevelCode,
  isAuthenticated: state.profile.isAuthenticated,
  accountNumbers: state.profile.accountNumbers,
  billingAccounts: state.profile.billingAccounts,
  division: state.profile.division,
  userId: state.profile.userId,
  userEmail: state.profile.email,
});

const mdtp = (dispatch) => ({
  putReduxServiceLevelQuotes: (serviceLevelQuotes) =>
    dispatch(putReduxServiceLevelQuotes(serviceLevelQuotes)),
  putReduxServiceLevelCode: (serviceLevelCode) =>
    dispatch(putReduxServiceLevelCode(serviceLevelCode))
});

export default connect(
  mstp,
  mdtp
)(CreateShipmentForm);
